import $ from 'jquery';
var Register = (function () {
    'use strict';

    function init() {

        $('form').submit(function () {

            const checkBoxes = document.querySelectorAll('.transplantType input[type="checkbox"]');
            var errorDiv = document.getElementById("error-message-reg");
            var checkedOne = Array.prototype.slice.call(checkBoxes).some(x => x.checked);
            if (checkedOne != true) {
                document.getElementById("TransplantTypeContainer").classList.add("checkbox-error");
                errorDiv.style.display = "block";
                return false;
            }
            else {
                document.getElementById("TransplantTypeContainer").classList.remove("checkbox-error");
            }
            var model = {};
            model.FirstName = $('#FirstName').val();
            model.LastName = $('#LastName').val();
            model.EmailAddress = $('#EmailAddress').val();
            model.OptIn = $('#OptIn').val();

            model.TransplantType_SolidOrganTransplants = false;
            if ($('#SolidOrganTransplants').is(':checked')) {
                model.TransplantType_SolidOrganTransplants = true;
            }
            model.TransplantType_BoneMarrowTransplants = false;
            if ($('#BoneMarrowTransplants').is(':checked')) {
                model.TransplantType_BoneMarrowTransplants = true;
            }
            model.TransplantType_Other = false;
            if ($('#Other').is(':checked')) {
                model.TransplantType_Other = true;
            }
            if (model.TransplantType_SolidOrganTransplants == false && model.TransplantType_BoneMarrowTransplants == false && model.TransplantType_Other == false) {
                return false;
            }

            $.validator.unobtrusive.parse($('form'));
            if ($(this).valid()) {

                $.ajax({
                    url: "/register/registersubmit",
                    type: "POST",
                    dataType: "json",
                    contentType: 'application/x-www-form-urlencoded; charset=utf-8',
                    data: { model: model },
                    success: function () {
                        document.getElementById("thank-you").style.display = "block";
                        document.getElementById("register").style.display = "none";
                    },
                    error: function () {
                    }
                });
            }
            return false;
        });

        const checkboxTransplantTypes = document.querySelectorAll('.transplantType input[type="checkbox"]');
        var errorDiv = document.getElementById("error-message-reg");

        for (var checkbox of checkboxTransplantTypes) {
            checkbox.addEventListener('change', function () {
                const transplantTypes = document.querySelectorAll('.transplantType input[type="checkbox"]');
                var checkedOnetransplantType = Array.prototype.slice.call(transplantTypes).some(x => x.checked);
                if (checkedOnetransplantType == false) {
                    document.getElementById("TransplantTypeContainer").classList.add("checkbox-error");
                    errorDiv.style.display = "block";
                    return false;
                }
                else {
                    document.getElementById("TransplantTypeContainer").classList.remove("checkbox-error");
                    errorDiv.style.display = "none";
                    return true;
                }

            });
        }
    }
    return {
        init: init
    };

}());

export default Register;