import 'jquery';
import { validator } from 'jquery-validation';
import 'jquery-validation-unobtrusive';
import register from "./Register";

// extend jquery range validator to work for required checkboxes
var defaultRangeValidator = validator.methods.range;
validator.methods.range = function (value, element, param) {
  if (element.type === 'checkbox') {
    return element.checked;
  } else {
    return defaultRangeValidator.call(this, value, element, param);
  }
};

(function () {
  document.addEventListener('readystatechange', () => {
    if(document.readyState === 'complete') {
      register.init();
    }
  });
})();